// @ts-nocheck
import { createChart, IChartApi, HistogramData } from 'lightweight-charts'
import React, { useEffect, useRef, useState } from 'react'
import { PairKLine } from '../../api/swap'
import { isMobile } from 'react-device-detect'

interface ChartComponentProps {
  address: string
  type: '1h' | '1d' | '1w' // Define allowed types
}

export default function ChartComponent({ address, type }: ChartComponentProps) {
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const [volumeKData, setVolumeKData] = useState<HistogramData[]>([])
  const chartRef = useRef<IChartApi | null>(null)
  const legendRef = useRef<HTMLDivElement | null>(null)
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  // Fetch K-line data
  useEffect(() => {
    async function getKlineData() {
      if (!address) return
      setLoading(true)
      setError(null)
      try {
        const { data } = await PairKLine({ pair: address, t: type })
        const formattedData: HistogramData[] = data.map((i: any) => ({
          time: i.timestamp,
          value: i.volume
        }))
        setVolumeKData(formattedData)
      } catch (error) {
        console.error('获取 Kline 数据失败:', error)
        setError('Failed to load data.')
      } finally {
        setLoading(false)
      }
    }

    getKlineData()
  }, [address, type])

  // Create and update chart
  useEffect(() => {
    if (!volumeKData.length || !chartContainerRef.current) return

    // Cleanup existing chart and associated elements
    if (chartRef.current) {
      chartRef.current.remove()
      chartRef.current = null
    }
    if (legendRef.current) {
      legendRef.current.remove()
      legendRef.current = null
    }
    if (tooltipRef.current) {
      tooltipRef.current.remove()
      tooltipRef.current = null
    }

   
    // Create new chart with localization
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        background: { type: 'solid', color: 'transparent' },
        textColor: '#333',
        fontFamily: 'Inter',
        fontSize: 12,
        attributionLogo: false
      },
      handleScroll: false,
      handleScale: false,
      grid: {
        vertLines: { visible: false },
        horzLines: { visible: false }
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0
        },
        borderVisible: false,
        visible: false
      },
      timeScale: {
        barSpacing: 0,
        borderVisible: false,
        timeVisible: true,
        secondsVisible: false,
        // Use the custom timeFormatter
        // localization: {
        //   timeFormatter: customTimeFormatter
        // }
      },
      // rightPriceScale: { visible: false },
      leftPriceScale: { visible: false },
      // localization: {
      //   timeFormatter: customTimeFormatter
      // }
    })

    chartRef.current = chart

    // Add Histogram Series
    const barSeries = chart.addHistogramSeries({
      color: '#12B298',
      priceFormat: { type: 'volume' },
      priceScaleId: ''
    })

    barSeries.priceScale().applyOptions({
      scaleMargins: { top: 0.7, bottom: 0 }
    })

    barSeries.setData(volumeKData)

    const firstDataPoint = volumeKData[0].time
    const lastDataPoint = volumeKData[volumeKData.length - 1].time
    chart.timeScale().setVisibleRange({ from: firstDataPoint, to: lastDataPoint })

    chart.applyOptions({
      width: chartContainerRef.current.clientWidth,
      autoScale: false,
      scaleMargins: { top: 0.9, bottom: 0 }
    })

    // Add Legend
    const legend = document.createElement('div')
    legend.style.cssText = `
      position: absolute;
      left: 12px;
      top: 12px;
      z-index: 1;
      font-size: 16px;
      font-family: 'Inter', sans-serif;
      line-height: 18px;
      color: #000;
    `
    chartContainerRef.current.appendChild(legend)
    legendRef.current = legend // Store reference for cleanup

    const firstRow = document.createElement('div')
    firstRow.innerHTML = `<div class="price-highlight">$${volumeKData[volumeKData.length - 1]?.value || '0.00'}</div>`
    legend.appendChild(firstRow)

    // Add Tooltip
    const tooltip = document.createElement('div')
    tooltip.style.cssText = `
      position: absolute;
      display: none;
      background: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
      font-size: 15px;
      color: black;
      pointer-events: none;
      z-index: 10;
      font-family: 'Inter', sans-serif;
    `
    chartContainerRef.current.appendChild(tooltip)
    tooltipRef.current = tooltip // Store reference for cleanup

    chart.subscribeCrosshairMove(param => {
      let priceFormatted = volumeKData[volumeKData.length - 1].value
      if (param.time) {
        const dataPoint = param.seriesData.get(barSeries)
        const price = dataPoint?.value ?? dataPoint?.close
        priceFormatted = price ? price.toFixed(2) : '0'

        const { point } = param
        if (point) {
          tooltip.style.left = `${point.x + 10}px` // Add offset
          tooltip.style.top = `${point.y + 10}px` // Add offset
          tooltip.style.display = 'block'
          tooltip.innerHTML = `$${priceFormatted}<br />`
        }
      } else {
        tooltip.style.display = 'none'
      }
      firstRow.innerHTML = `<div class="price-highlight">$${priceFormatted}</div>`
    })

    chart.timeScale().fitContent()

    // Handle window resize
    const handleResize = () => {
      if (chartRef.current && chartContainerRef.current) {
        chartRef.current.applyOptions({ width: chartContainerRef.current.clientWidth })
        chartRef.current.timeScale().fitContent()
      }
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize)
      chart.remove()
      chartRef.current = null
      if (legendRef.current) {
        legendRef.current.remove()
        legendRef.current = null
      }
      if (tooltipRef.current) {
        tooltipRef.current.remove()
        tooltipRef.current = null
      }
    }
  }, [volumeKData, type]) // Include 'type' to update formatter when it changes

  return (
    <div
      ref={chartContainerRef}
      style={{
        position: 'relative',
        width: '100%',
        height: isMobile ? '300px' : '400px'
      }}
    >
     
    </div>
  )
}
