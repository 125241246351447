// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import { Transactions as getTransactions, PoolTransactions } from '../../api/swap'
import styled from 'styled-components'
import subAddress from '../../utils/subAddress'
import multiply from '../../utils/multiply'
import formatLongNumber from '../../utils/formatLongNumber'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
let BROWSER = process.env.REACT_APP_BROWSER

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.45);
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #a7bfa7;
  padding: 25px 60px;
  margin-bottom: 10px;

  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(13px);

  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.68);
  }
  padding: 16px 30px 19px 70px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.68);
  }
  .left {
    display: flex;
    flex-direction: column;
    flex: 1.2;
    & > span {
      font-weight: 400;
      font-size: 16px;
      color: #181818;
      opacity: 0.6;
    }
    & > div {
      align-items: baseline;
      margin-top: 8px;
      display: flex;
      /* align-items: flex-end; */
      & > span {
        opacity: 0.6;
        font-weight: 400;
        font-size: 16px;
        color: #181818;
      }
      div {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-left: 6px;
        font-weight: 700;
        font-size: 20px;
        opacity: 0.6;
      }
    }
  }
  .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    span {
      &:first-child {
        opacity: 0.6;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      &:last-child {
        margin-top: 10px;
        font-weight: 500;
        font-size: 18px;
        color: #181818;
      }
    }
  }
  .link {
    &:hover {
      opacity: 0.6;
    }
  }
`

export default function TransactionsMobile({ address, type = 'token' }) {
  const history = useHistory()

  const [list, setList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (type === 'token') {
          const { data } = await getTransactions({ Token: address ? address : '' })
          setList(data)
        } else {
          let { data } = await PoolTransactions({ Pair: address })
          setList(data)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [address])

  function isMilliseconds(timestamp: any) {
    if (typeof timestamp !== 'string' && typeof timestamp !== 'number') {
      throw new TypeError('Parameter must be a string or a number')
    }
    return timestamp.toString().length === 13
  }

  const handleTimeAgo = (time1: any, time2 = moment(), flag: any) => {
    // Ensure time1 and time2 are in milliseconds
    time1 = isMilliseconds(time1) ? time1 : time1 * 1000
    time2 = isMilliseconds(time2.valueOf()) ? time2.valueOf() : time2.valueOf() * 1000

    // Calculate the duration between time1 and time2
    const duration = moment.duration(moment(time2).diff(moment(time1)))

    // Get total days
    const totalDays = duration.asDays() // Get the total difference in days

    // Define time units
    const timeUnits = [
      { unit: 'day', value: Math.floor(totalDays) }, // Use integer number of days
      { unit: 'hr', value: duration.hours() },
      { unit: 'min', value: duration.minutes() },
      { unit: 'sec', value: duration.seconds() }
    ]

    // Iterate through the units to get the most significant one
    for (const { unit, value } of timeUnits) {
      if (value > 0) {
        return `${value} ${unit}${value > 1 ? 's' : ''} ${flag ? '' : 'ago'}`
      }
    }

    return '1 sec' // Fallback for very short time intervals
  }

  function jump(token) {
    history.push('/explore/tokens/' + token)
  }

  function jumpBrowser(address) {
    window.open(BROWSER + '/tx/' + address)
  }

  function jumpAddressBrowser(address) {
    window.open(BROWSER + '/address/' + address)
  }

  return (
    <div className="list" style={{ marginTop: '16px' }}>
      {list.map((item, index) => {
        return (
          <Item
            key={index}
            onClick={() => {
              jumpBrowser(item.transactionHash)
            }}
          >
            <div className="left">
              <span>{handleTimeAgo(item.ts)}</span>
              <div>
                <span> {item.type}</span>
                <div>
                  <span
                    className="link"
                    onClick={e => {
                      e.stopPropagation()
                      jump(item.token0)
                    }}
                  >
                    {item.symbol0}
                  </span>
                  {item.type === 'add' || item.type === 'remove' ? 'and ' : 'for '}{' '}
                  <span
                    className="link"
                    onClick={e => {
                      e.stopPropagation()
                      jump(item?.token1)
                    }}
                  >
                    {item.symbol1}
                  </span>
                </div>
              </div>
            </div>
            <div className="item">
              <span>USD</span>
              <span> ${item?.usd > 0 && item?.usd < 0.01 ? '<0.01' : multiply(formatLongNumber(item?.usd, 2))}</span>
            </div>

            <div className="item">
              <span>{formatLongNumber(item.symbol0)}</span>
              <span>
                {item?.amount0 > 0 && item?.amount0 < 0.01 ? '<0.01' : multiply(formatLongNumber(item?.amount0, 6))}
              </span>
            </div>

            <div className="item">
              <span>{formatLongNumber(item.symbol1)}</span>
              <span>
                {item?.amount1 > 0 && item?.amount1 < 0.01 ? '<0.01' : multiply(formatLongNumber(item?.amount1, 2))}
              </span>
            </div>

            <div className="item">
              <span>Wallet</span>
              <span
                className="link"
                onClick={e => {
                  e.stopPropagation()
                  jumpAddressBrowser(item?.from)
                }}
              >
                {subAddress(item?.from, 5)}{' '}
              </span>
            </div>
            {/*   <div className="item-left">
              <div className="price">
                ${multiply(formatLongNumber(item?.amount1, 6))}
                <span>{handleTimeAgo(item.ts)}</span>
              </div>
              <div className="data">
                <div
                  onClick={e => {
                    e.stopPropagation()
                    jump(item.token0)
                  }}
                >
                  <span> {formatLongNumber(item.symbol0)}</span> {multiply(formatLongNumber(item?.amount0, 6))}
                </div>
                <div
                  onClick={e => {
                    e.stopPropagation()
                    jump(item.token1)
                  }}
                >
                  <span>{formatLongNumber(item.symbol1)}</span> {multiply(formatLongNumber(item?.amount1, 6))}
                </div>
              </div>
            </div>
            <div className="item-right">
              <div className="address"> {subAddress(item?.from, 5)}</div>
              <div className="token">
                <span>{item.type}</span>
                <div
                  className="link"
                  onClick={e => {
                    e.stopPropagation()
                    jump(item.token1)
                  }}
                >
                  {item.symbol0}
                </div>
                {item.type === 'add' || item.type === 'remove' ? 'and ' : 'for '}{' '}
                <div
                  className="link"
                  onClick={e => {
                    e.stopPropagation()
                    jumpAddressBrowser(item?.from)
                  }}
                >
                  {item.symbol1}
                </div>
              </div>
            </div> */}
          </Item>
        )
      })}
    </div>
  )
}
