// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react'
import { createChart } from 'lightweight-charts'
import { TokenKLine } from '../../api/swap'
import { isMobile } from 'react-device-detect'

export default function TokenChart({ address, type }) {
  const chartContainerRef = useRef(null)
  const chartRef = useRef(null)
  const legendRef = useRef(null)
  const tooltipRef = useRef(null)
  const [kData, setKdata] = useState([])

  useEffect(() => {
    if (!kData.length) return

    // 清理旧的图表和元素
    if (chartRef.current) {
      chartRef.current.remove()
      chartRef.current = null
    }
    if (legendRef.current) {
      chartContainerRef.current.removeChild(legendRef.current)
      legendRef.current = null
    }
    if (tooltipRef.current) {
      chartContainerRef.current.removeChild(tooltipRef.current)
      tooltipRef.current = null
    }

    // 创建新的图表
    const chartOptions = {
      layout: {
        textColor: 'black',
        background: { type: 'solid', color: '#f9f9f9' }
      }
    }

    const chart = createChart(chartContainerRef.current, chartOptions)
    chartRef.current = chart

    chart.applyOptions({
      handleScroll: false,
      handleScale: false,
      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0
        },
        borderVisible: false,
        // visible: false
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        }
      },
      grid: {
        vertLines: { visible: false },
        horzLines: { visible: false }
      },
      timeScale: {
        barSpacing: 5,
        timeVisible: true,
        borderVisible: false,
        secondsVisible: false
      },
      layout: {
        background: {
          type: 'solid',
          color: 'transparent'
        },
        textColor: '#333',
        attributionLogo: false
      }
    })

    const areaSeries = chart.addAreaSeries({
      topColor: 'rgba(25, 222, 166, 1)',
      bottomColor: 'rgba(25, 222, 166, 0)',
      lineColor: 'rgba(23, 209, 157, 1)',
      lineWidth: 2,
      priceLineVisible: false,
      crossHairMarkerVisible: false
    })

    areaSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.7,
        bottom: 0
      }
    })
    areaSeries.setData(kData)

    // 创建图例
    const legend = document.createElement('div')
    legend.style.position = 'absolute'
    legend.style.left = '0px'
    legend.style.top = '12px'
    legend.style.zIndex = '1'
    legend.style.fontSize = '16px'
    legend.style.fontFamily = 'Inter'
    legend.style.lineHeight = '18px'
    chartContainerRef.current.appendChild(legend)
    legendRef.current = legend

    const firstRow = document.createElement('div')
    firstRow.innerHTML = `<div class="price-highlight">$${kData[kData.length - 1]?.value || '0.00'}</div>`
    legend.appendChild(firstRow)

    // 创建工具提示
    const tooltip = document.createElement('div')
    tooltip.style.position = 'absolute'
    tooltip.style.display = 'none'
    tooltip.style.background = '#f9f9f9'
    tooltip.style.border = '1px solid #ccc'
    tooltip.style.borderRadius = '4px'
    tooltip.style.padding = '8px'
    tooltip.style.fontSize = '15px'
    tooltip.style.color = 'black'
    tooltip.style.pointerEvents = 'none'
    tooltip.style.zIndex = '10'
    tooltip.style.fontFamily = 'Inter'
    chartContainerRef.current.appendChild(tooltip)
    tooltipRef.current = tooltip

    // 订阅跨光标移动事件
    chart.subscribeCrosshairMove(param => {
      let priceFormatted = kData[kData.length - 1].value

      if (param.time) {
        const dataPoint = param.seriesData.get(areaSeries)
        const price = dataPoint?.value ?? dataPoint?.close
        priceFormatted = price ? price : '0'

        const { point } = param
        if (point && tooltipRef.current) {
          tooltipRef.current.style.left = `${point.x}px`
          tooltipRef.current.style.top = `${point.y}px`
          tooltipRef.current.style.display = 'block'
          tooltipRef.current.innerHTML = `$${priceFormatted}<br />`
        }
      } else {
        if (tooltipRef.current) {
          tooltipRef.current.style.display = 'none'
        }
      }
      if (legendRef.current) {
        legendRef.current.innerHTML = `<div class="price-highlight">$${priceFormatted}</div>`
      }
    })

    chart.timeScale().fitContent()

    // 清理函数
    return () => {
      if (chartRef.current) {
        chartRef.current.remove()
        chartRef.current = null
      }
      if (legendRef.current) {
        chartContainerRef.current.removeChild(legendRef.current)
        legendRef.current = null
      }
      if (tooltipRef.current) {
        chartContainerRef.current.removeChild(tooltipRef.current)
        tooltipRef.current = null
      }
    }
  }, [kData, type])

  useEffect(() => {
    async function getKlineData() {
      try {
        if (!address) {
          return
        }
        let { data } = await TokenKLine({ token: address, t: type })
        data.forEach(i => {
          i.time = i.timestamp
          i.value = i.openPrice
        })
        setKdata(data)
      } catch (error) {
        console.error('获取 Kline 数据失败:', error)
      }
    }

    getKlineData()
  }, [address, type])

  return (
    <div
      ref={chartContainerRef}
      style={{ position: 'relative', width: '100%', height: isMobile ? '300px' : '400px' }}
    />
  )
}

// CSS Styles
const styles = document.createElement('style')
styles.innerHTML = `
  .price-highlight {
    margin-top: 20px;
    color: #000;
    font-size: 30px;
    font-weight: 500;
  }
`
document.head.appendChild(styles)
